import type { NextPage } from 'next';
import React from 'react';
import { AuthGuard } from '../src/components/general/Auth/AuthGuard';
import { CMSPage } from '../src/components/frontend/areas/CMSPage/CMSPage';
import { StageWrapper } from '../src/components/stages/StageWrapper';

const Index: NextPage = () => {
    return (
        <AuthGuard>
            <StageWrapper testId="stage-user-home">
                <CMSPage slug="home" />
            </StageWrapper>
        </AuthGuard>
    );
};
export default Index;

import React, { useMemo } from 'react';
import { Typography } from '../../../../general/typo/Typography/Typography';
import { useTranslation } from '../../../../general/Translations/hooks/useTranslation';
import { useAccess } from '../../../../general/access/hooks/useAccess';
import { EAccess } from '../../../../../interfaces/role/IAccess';
import { Button } from '../../../../general/controls/button/Button';

export const CMSPageNotFound: React.FC = () => {
    const { getT } = useTranslation();

    const { hasAccess } = useAccess();

    const hasContentAccess = useMemo(() => {
        return hasAccess([EAccess.mContent, EAccess.qContent]);
    }, [hasAccess]);

    return (
        <section className="relative flex flex-col gap-4">
            <Typography variant={'h1'}>{getT('cmsPageNotFoundTitle')}</Typography>
            <Typography variant={'description'}>{getT('cmsPageNotFoundDescription')}</Typography>

            {hasContentAccess && (
                <div className="mt-4">
                    <Button
                        // iconStart={<PlusIcon />}
                        color="cloudbar"
                        href="/admin/pagecms/"
                        target="cloudbaradmin"
                    >
                        {getT('cmsPageNotFoundButtonLabel')}
                    </Button>
                </div>
            )}
        </section>
    );
};

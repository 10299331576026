import React, { useEffect, useMemo } from 'react';
import { usePageCMSQuery } from '../../../general/hooks/pageCMS/usePageCMSQuery';
import { SkeletonLine } from '../../../general/elements/Skeletons/SkeletonLine';
import { HeadlineScene } from '../headlineScene/HeadlineScene';
import { CMSPageLoader } from './CMSPageLoader';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { cmsPageDataSelector, cmsSlugSelector } from './state/CMSPage.state';
import { adminPageLayoutSelector } from './state/pageEditorStateSelector';
import { tenantSelector } from '../../../general/state/tenantState';
import { CMSPageNotFound } from './elements/CMSPageNotFound';

export interface ICMSPage {
    slug: string;
}

export const CMSPage: React.FC<ICMSPage> = (props) => {
    const { slug } = props;

    const [contentPage, setContentPage] = useRecoilState(cmsPageDataSelector);
    const [currentSlug, setCurrentSlug] = useRecoilState(cmsSlugSelector);
    const setPageLayout = useSetRecoilState(adminPageLayoutSelector);

    // TODO Temporary! until we can hide titles
    const tenant = useRecoilValue(tenantSelector);
    const showTitles = useMemo(() => {
        return tenant?.name.toLocaleLowerCase() !== 'bertelsmann';
    }, [tenant?.name]);

    const { data, isPending, refetch } = usePageCMSQuery({
        slug: currentSlug ?? slug ?? null,
        createOnNotFound: false,
    });

    // eslint-disable-next-line no-console
    // console.log('Page-Data', data);

    useEffect(() => {
        if (slug === undefined) {
            setContentPage(null);
            setPageLayout(null);
            return;
        }

        if (currentSlug === undefined) {
            setCurrentSlug(slug);
        }

        if (slug !== currentSlug) {
            setCurrentSlug(slug);
        }
    }, [currentSlug, isPending, refetch, setContentPage, setCurrentSlug, setPageLayout, slug]);

    useEffect(() => {
        if (data) {
            setContentPage(data);
            setPageLayout(data.layouts);
        }
    }, [data, setContentPage, setPageLayout]);

    const showSkeleton = useMemo((): boolean => {
        return isPending && !data;
    }, [data, isPending]);

    return (
        <>
            {showSkeleton && (
                <div className="relative flex w-full flex-col gap-4">
                    <div className="hidden md:flex">
                        <div className="grid grid-cols-2 gap-4">
                            <SkeletonLine height={400} />
                            <SkeletonLine height={400} />
                        </div>

                        <SkeletonLine height={400} />
                        <SkeletonLine height={400} />
                    </div>
                    <div className="flex md:hidden flex-col gap-4">
                        <div className="grid grid-cols-2 gap-4">
                            <SkeletonLine height={100} />
                            <SkeletonLine height={100} />
                        </div>

                        <SkeletonLine height={100} />
                        <SkeletonLine height={100} />

                        <div className="grid grid-cols-3 gap-4">
                            <SkeletonLine height={100} />
                            <SkeletonLine height={100} />
                            <SkeletonLine height={100} />
                        </div>
                    </div>
                </div>
            )}

            {!data && !isPending && <CMSPageNotFound />}

            {!showSkeleton && contentPage && (
                <div className="relative flex w-full flex-col gap-4">
                    {showTitles && (
                        <div className="w-full lg:px-0">
                            <HeadlineScene
                                headline={contentPage.title.baseTranslation ?? ''}
                                subline={data?.subLine?.baseTranslation ?? undefined}
                            />
                        </div>
                    )}

                    {contentPage && !isPending && <CMSPageLoader />}
                </div>
            )}
        </>
    );
};
